import { createSlice } from "@reduxjs/toolkit";
import { MODE } from "../../_helpers";
const initialState = {
  canOpenRightSidePanel: false,
  canOpenLeftSidePanel: false,
  currentMode: {
    mode: MODE.INITIAL_STAGE,
    viewer: MODE.ACTIVATE_INITIAL_OVERLAY,
    props: {
      prevMode: MODE.INITIAL_STAGE,
      previewImage: { show: false, imageObject: {} },
    },
  },
  category: {},
  contents: [],
  activeContent: [],
  activeMarker: {},
};

const setupSlice = createSlice({
  name: "setup",
  initialState,
  reducers: {
    setSelectedCategory(state, action) {
      state.category = action.payload.category || {};
    },
    setProjects(state, action) {
      const newContents = action.payload.contents;

      // Create a map of existing projects by ID
      const existingProjectsMap = new Map(state.contents.map((item) => [item.id, item]));
    
      // Iterate through new contents and either update or add new projects
      newContents.forEach((newProject) => {
        if (existingProjectsMap.has(newProject.id)) {
          // If project exists, update it
          state.contents = state.contents.map((project) =>
            project.id === newProject.id ? newProject : project
          );
        } else {
          // If project doesn't exist, add it
          state.contents.push(newProject);
        }
      });;
    },
    setCurrentMode(state, action) {
      if (action.payload.mode) {
        state.currentMode = {
          mode: action.payload.mode,
          viewer: action.payload.viewer || state.currentMode.viewer,
          props: {
            ...state.currentMode.props,
            ...action.payload.props,
          },
        };
      } else if (action.payload.viewer) {
        state.currentMode = {
          ...state.currentMode,
          viewer: action.payload.viewer,
          props: {
            ...state.currentMode.props,
            ...action.payload.props,
          },
        };
      } else {
        state.currentMode = {
          ...state.currentMode,
          props: {
            ...state.currentMode.props,
            ...action.payload.props,
          },
        };
      }
    },
    clearSetup(state, action) {
      state.currentMode = {
        mode: MODE.INITIAL_STAGE,
        viewer: MODE.ACTIVATE_INITIAL_OVERLAY,
        props: {
          prevMode: MODE.INITIAL_STAGE,
          previewImage: { show: false, imageObject: {} },
        },
      };
      state.category = {};
      state.contents = [];
      state.canOpenRightSidePanel = false;
      state.canOpenLeftSidePanel = false;
      state.activeContent = [];
      state.activeMarker = {};
    },
    setSite(state, action) {
      const { project_id, site } = action.payload;
      const projectIndex = state.contents.findIndex(
        (project) => parseInt(project.id) === parseInt(project_id)
      );
    
      if (projectIndex !== -1) {
        const project = state.contents[projectIndex];
        const siteIndex = project.sites.findIndex(
          (existingSite) => parseInt(existingSite.id) === parseInt(site.id)
        );
    
        if (siteIndex !== -1) {
          project.sites[siteIndex] = { ...project.sites[siteIndex], ...site };
        } else {
          project.sites.push(site);
        }
        state.activeContent = [project];
      }
    },    
    setSiteGoogleMapPolygonArea(state, action) {
      const { project_id, site_id, polygon } = action.payload;
      const projectIndex = state.contents.findIndex(
        (project) => parseInt(project.id) === parseInt(project_id)
      );

      if (projectIndex !== -1) {
        const siteIndex = state.contents[projectIndex].sites.findIndex(
          (site) => parseInt(site.id) === parseInt(site_id)
        );

        if (siteIndex !== -1) {
          if (polygon) {
            const { id, coordinates, options } = polygon;
            state.contents[projectIndex].sites[siteIndex].polygon_marker = {
              id,
              project_id,
              site_id,
              coordinates,
              options,
            };
          } else {
            state.contents[projectIndex].sites[siteIndex].polygon_marker = null;
          }
          state.activeContent = [state.contents[projectIndex]];
        }
      }
    },
    setSiteGoogleMapPolygonAreaOnDelete(state, action) {
      const { project_id, site_id, polygon } = action.payload;
      const projectIndex = state.contents.findIndex(
        (project) => parseInt(project.id) === parseInt(project_id)
      );

      if (projectIndex !== -1) {
        const siteIndex = state.contents[projectIndex].sites.findIndex(
          (site) => parseInt(site.id) === parseInt(site_id)
        );

        if (siteIndex !== -1) {
          state.contents[projectIndex].sites[siteIndex].polygon_marker = null;
          state.contents[projectIndex].sites[siteIndex].buildings = [];
          state.activeContent = [state.contents[projectIndex]];
        }
      }
    },
    updateBuildingMarker(state, action) {
      const { project_id, site_id, building_id, marker } = action.payload;
      const project = state.contents.find(
        (project) => parseInt(project.id) === parseInt(project_id)
      );
      if (project) {
        const foundSite = project.sites.find((site) => site.id === site_id);
        if (foundSite) {
          const foundBuilding = foundSite.buildings.find(
            (building) => parseInt(building.id) === parseInt(building_id)
          );
          if (foundBuilding) {
            foundBuilding.marker = marker.marker;
            state.activeContent = [project];
          }
        }
      }
    },
    setBuilding(state, action) {
      const { building, site } = action.payload;
      const { id, project_id } = site;

      const project = state.contents.find(
        (project) => project.id === Number(project_id)
      );
      if (project) {
        const foundSite = project.sites.find((site) => site.id === id);
        if (foundSite) {
          foundSite.buildings = foundSite.buildings || [];
          foundSite.buildings.push(building);
        }
        state.activeContent = [project];
      }
    },

    updateBuilding(state, action) {
      const { building, site } = action.payload;
      const { id, project_id } = site;

      const project = state.contents.find(
        (project) => project.id === Number(project_id)
      );

      if (project) {
        const foundSite = project.sites.find((site) => site.id === id);
        if (foundSite) {
          foundSite.buildings = foundSite.buildings || [];
          // Find the existing building by its id
          const existingBuildingIndex = foundSite.buildings.findIndex(
            (b) => b.id === building.id
          );

          if (existingBuildingIndex !== -1) {
            // Update the existing building with new properties
            foundSite.buildings[existingBuildingIndex] = {
              ...foundSite.buildings[existingBuildingIndex],
              ...building,
            };
          }
        }
        // Update the active content
        state.activeContent = [project];
      }
    },
    deleteBuilding(state, action) {
      const { building_id, site_id, project_id } = action.payload;
      // Find the project by project_id
      const project = state.contents.find(
        (project) => project.id === Number(project_id)
      );

      if (project) {
        // Find the site within the project by site_id
        const foundSite = project.sites.find((site) => site.id === site_id);
        if (foundSite) {
          // Ensure the buildings array exists
          foundSite.buildings = foundSite.buildings || [];

          // Find the index of the building to delete by its id
          const buildingIndex = foundSite.buildings.findIndex(
            (b) => b.id === building_id
          );

          if (buildingIndex !== -1) {
            // Remove the building from the buildings array
            foundSite.buildings.splice(buildingIndex, 1);
          }
        }
        // Update the active content
        state.activeContent = [project];
      }
    },
    setFloor(state, action) {
      const { floor, site, building } = action.payload;
      const { id: site_id, project_id } = site;
      const { id } = building;

      const project = state.contents.find(
        (project) => project.id === Number(project_id)
      );

      if (project) {
        const foundSite = project.sites.find((site) => site.id === site_id);
        if (foundSite) {
          const foundBuilding = foundSite.buildings.find(
            (building) => building.id === id
          );

          if (foundBuilding) {
            foundBuilding.floors = foundBuilding.floors || [];
            foundBuilding.floors.push(floor);
          }
        }
        state.activeContent = [project];
      }
    },
    deleteProject(state, action) { 
      const { project_id } = action.payload; 
      state.contents = state.contents.filter(
        (project) => project.id !== Number(project_id)
      );      
    },
    updateFloor(state, action) {
      const { floor, site_id, building_id, project_id } = action.payload;
      const project = state.contents.find(
        (project) => project.id === Number(project_id)
      );
    
      if (project) {
        const foundSite = project.sites.find((site) => site.id === site_id);
    
        if (foundSite) {
          const foundBuilding = foundSite.buildings.find(
            (building) => building.id === building_id
          );
    
          if (foundBuilding) {
            const foundFloorIndex = foundBuilding.floors.findIndex(
              (f) => f.floor_polygon_id === floor.floor_polygon_id
            );
    
            if (foundFloorIndex !== -1) {
              foundBuilding.floors[foundFloorIndex] = {
                ...foundBuilding.floors[foundFloorIndex],
                ...floor,
              };
            }
            state.activeContent = [project];
          }
        }
      }
    },
    updateFloorObjectMap(state, action) {
      const { floor_object_map, site_id, building_id, project_id, floor_polygon_id } = action.payload;
      const project = state.contents.find(
        (project) => project.id === Number(project_id)
      );
      if (project) {
        const foundSite = project.sites.find((site) => site.id === site_id);
        if (foundSite) {
          const foundBuilding = foundSite.buildings.find(
            (building) => building.id === building_id
          );
          if (foundBuilding) {
            const foundFloorIndex = foundBuilding.floors.findIndex(
              (f) => f.floor_polygon_id === floor_polygon_id
            );
            if (foundFloorIndex !== -1) {
              foundBuilding.floors[foundFloorIndex] = {
                ...foundBuilding.floors[foundFloorIndex],
                floor_object_map, // Only updating the floor_object_map property
              };
            }
            state.activeContent = [project];
          }
        }
      }
    },    
    setCanOpenRightSidePanel(state, action) {
      state.canOpenRightSidePanel = action.payload.canOpenRightSidePanel;
    },
    setCanOpenLeftSidePanel(state, action) {
      state.canOpenLeftSidePanel = action.payload.canOpenLeftSidePanel;
    },
    setActiveProject(state, action) {
      state.activeContent = [action.payload.activeContent];
    },
    setActiveMarker(state, action) {
      state.activeMarker = action.payload.marker;
    },
    setClearActiveProject(state, action) {
      state.activeContent = [];
    },
  },
});
export const {
  setSelectedCategory,
  setProjects,
  setCurrentMode,
  clearSetup,
  setSite,
  setBuilding,
  setFloor,
  setCanOpenRightSidePanel,
  setCanOpenLeftSidePanel,
  setSiteGoogleMapPolygonArea,
  setSiteGoogleMapPolygonAreaOnDelete,
  setActiveProject,
  updateBuildingMarker,
  updateBuilding,
  deleteBuilding,
  setActiveMarker,
  updateFloor,
  updateFloorObjectMap,
  deleteProject,
  setClearActiveProject
} = setupSlice.actions;
export const getSelectedCategory = (state) => state.setup.category;
export const getCanOpenRightSidePanel = (state) =>
  state.setup.canOpenRightSidePanel;
export const getCanOpenLeftSidePanel = (state) =>
  state.setup.canOpenLeftSidePanel;
export const getProjects = (state) => state.setup.contents;
export const getActiveProject = (state) => state.setup.activeContent;
export const getCurrentMode = (state) => state.setup.currentMode;
export const getActiveMarker = (state) => state.setup.activeMarker;
export default setupSlice.reducer;
