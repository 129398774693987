import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useSetupContext } from "../../context/SetupContext";

const CabniteList = () => {
  const { cabnites } = useSetupContext();
  return (
    <Droppable droppableId="cabnites">
      {(provided) => (
        <div>
          <div
            className="gird--item--section"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {cabnites.map((cabnite, index) => (
              <Draggable key={index} draggableId={`cabnites-${String(index)}`} index={index}>
                {(provided) => (
                  <div className="gird--item">
                    <div className="gird--item-content">
                      <img
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        src={cabnite.image_full_url}
                        alt={`Cabnite-${index}`}
                      />
                      <h6>{cabnite.name}</h6>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default CabniteList;
