/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { FloorViewerProvider } from "../../context/FloorViewerContext";
import SiteLayoutHolder from "./SiteLayoutHolder";
import { ViewerProvider } from "../../context/ViewerContext";
import { SetupProvider } from "../../context/SetupContext";
const SiteLayout = ({ children }) => {
  
  return (
    <SetupProvider>
      <ViewerProvider>
      <FloorViewerProvider>
        <SiteLayoutHolder>
          {children}
        </SiteLayoutHolder>
        </FloorViewerProvider>
      </ViewerProvider>
    </SetupProvider>
  );
};

export default SiteLayout;
