import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import ProtectedRoutes from "./ProtectedRoutes";
// Layouts
import SiteLayout from "../components/Layouts/SiteLayout";
import SignInPage from "../pages/SignIn";
import SelectRole from "../pages/SelectRole";
import GlobalLoader from "../components/GlobalLoader";
import SignUpPage from "../pages/SignUp";
const DashboardPage = lazy(() => import("../pages/Dashboard"));
const AppRouter = () => {
    return (
        <Router basename="/">
            <Routes>
            <Route 
                    path="/"
                    element={
                        <Suspense fallback={<div className="loading-loader-section"></div>}>
                            <SignInPage />
                        </Suspense>
                    }
                />
                 <Route
                    path="/sign-up"
                    element={
                        <Suspense fallback={<div className="loading-loader-section"></div>}>
                            <SignUpPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/viewer"
                    element={
                        <SiteLayout>
                            <Suspense fallback={<div className="loading-loader-section"></div>}>
                                <DashboardPage />
                            </Suspense>
                        </SiteLayout>
                    }
                />
                <Route
                    path="/sign-in"
                    element={
                        <Suspense fallback={<div className="loading-loader-section"></div>}>
                            <SignInPage />
                        </Suspense>
                    }
                />
                 <Route
                    path="/select-role"
                    element={
                        <Suspense fallback={<div className="loading-loader-section"></div>}>
                            <SelectRole />
                        </Suspense>
                    }
                />
                <Route element={<ProtectedRoutes />}>
                    {/* <Route path={`/pricing`} exact element={ <PricingPage />} /> */}
                </Route>
            </Routes>
            <GlobalLoader/>
        </Router>
    );
};

export default AppRouter;
