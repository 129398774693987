/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";
import ProjectListing from "./TabsAndMenus/ProjectListing";
import RackItemsList from "./DraggableListings/RackItemsList";
import StructureList from "./DraggableListings/StructureList";
import CabniteList from "./DraggableListings/CabniteList";
import RacksList from "./DraggableListings/RacksList";
const SideControlPanel = ({ leftToggleCollapse, openModal }) => {
  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  return (
    <div className="sidebar__section" id="sidebar-section">
      <div className="side__nav">
        <ul>
          <li>
            <div onClick={() => handleTabClick(1)}>
              <img src="./images/sidebar-icons/layout.svg" />
              <label>Layouts</label>
            </div>
          </li>
          <li>
            <div onClick={() => handleTabClick(2)}>
              <img src="./images/sidebar-icons/structure.svg" />
              <label>Structures</label>
            </div>
          </li>
          <li>
            <div onClick={() => handleTabClick(3)}>
              <img src="./images/sidebar-icons/cable.svg" />
              <label>Cables</label>
            </div>
          </li>
          <li>
            <div onClick={() => handleTabClick(4)}>
              <img
                src="./images/sidebar-icons/pathway.svg"
                id="rack-loder-xdcs"
              />
              <label>Pathways</label>
            </div>
          </li>
          <li>
            <div id="add-svg-button" onClick={() => handleTabClick(5)}>
              <img src="./images/sidebar-icons/racks.svg" />
              <label>Racks</label>
            </div>
          </li>
          <li>
            <div id="add-svg-button" onClick={() => handleTabClick(6)}>
              <img src="./images/sidebar-icons/rack-items.svg" />
              <label>Rack Items</label>
            </div>
          </li>
          <li>
            <div id="add-svg-button" onClick={() => handleTabClick(7)}>
              <img src="./images/sidebar-icons/active.svg" />
              <label>Active</label>
            </div>
          </li>
          <li>
            <div id="add-svg-button" onClick={() => handleTabClick(8)}>
              <img src="./images/sidebar-icons/nodes.svg" />
              <label>Nodes</label>
            </div>
          </li>
          <li>
            <div id="add-svg-button" onClick={() => handleTabClick(9)}>
              <img src="./images/sidebar-icons/mics.svg" />
              <label>Misc Objects</label>
            </div>
          </li>
        </ul>
      </div>
      <div className="side__tab">
        <div className="collapse-icon" onClick={leftToggleCollapse}>
          <i className="ri-arrow-left-double-line ri-lg"></i>
        </div>
        <div className="left__sidebar__content">
          <div className="tab__content__wrapper">
            {activeTab === 1 && <ProjectListing />}
            {activeTab === 2 && (
              <div className="side__tab__listing__section">
                <div className="side__tab__list__header__inner">
                  <h5>Structures</h5>
                </div>
                <StructureList />
              </div>
            )}
            {activeTab === 3 && (
              <div className="side__tab__listing__section">
                <div className="side__tab__list__header__inner">
                  <h5>Cables</h5>
                </div>
              </div>
            )}
            {activeTab === 5 && (
              <div className="side__tab__listing__section">
        
                <div className="side__tab__list__header__inner">
                  <h5>Cabnites</h5>
                </div>
                <CabniteList />
                <h5 className="mt-2 mb-2">Racks</h5>
                <RacksList />
              </div>
            )}
            {activeTab === 6 && (
              <div className="side__tab__listing__section">
                <div className="side__tab__list__header__inner">
                  <h5>Rack Items</h5>
                </div>
                <RackItemsList />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideControlPanel;
